import { defineStore } from 'pinia'
import { api } from '@/boot/axios'
import type { Order, OrderLine } from '@/stores/types'


export interface OrderStateInterface {
  orders: Order[]
  currentPage: number
  currentPageLines: number
  currentOrder: Order | null
  currentOrderLines: OrderLine[]
  ordersLoading: boolean
  currentOrderLoading: boolean
  count: number
  countCurrentLines: number
  search: string
  confirmedOrder: Order | null
  confirmedOrderLines: OrderLine[]
  countConfirmedLines: number
  confirmedOrderLoading: boolean
  hasError: boolean
}

export const useOrderStore = defineStore('order', {
  state: (): OrderStateInterface => ({
    orders: [],
    currentPage: 1,
    currentPageLines: 1,
    currentOrder: null,
    currentOrderLines: [],
    ordersLoading: false,
    currentOrderLoading: false,
    countCurrentLines: 0,
    count: 0,
    search: '',
    confirmedOrder: null,
    confirmedOrderLines: [],
    countConfirmedLines: 0,
    confirmedOrderLoading: false,
    hasError: false
  }),

  actions: {
    getAndResetError(): boolean {
      const err = this.hasError
      this.hasError = false
      return err
    },
    async fetchOrders(page: number = 1, pageSize: number = 50) {
      const params = { search: this.search, page: page, page_size: pageSize }
      try {
        this.ordersLoading = true
        const response = await api.get('orders/', {
          params: params
        })
        this.orders = response.data.results
        this.currentPage = page
        this.count = response.data.count
      } catch (error) {
        console.debug(error)
        this.hasError = true
      } finally {
        this.ordersLoading = false
      }
    },
    async getOrder(id: number) {
      try {
        this.currentOrderLoading = true
        const response = await api.get(`orders/${id}/`)
        this.currentOrder = response.data
        return this.currentOrder
      } catch (error) {
        console.debug(error)
        this.hasError = true
      } finally {
        this.currentOrderLoading = false
      }
    },
    async getConfirmedOrder(id: number) {
      try {
        const response = await api.get(`orders/${id}/`)
        this.confirmedOrder = response.data
        return this.confirmedOrder
      } catch (error) {
        console.debug(error)
        this.hasError = true
      }
    },
    async getOrderLines(
      id: number,
      type: string = 'current',
      page: number = 1,
      pageSize: number = 100
    ): Promise<OrderLine[]> {
      try {
        if (type === 'current') {
          this.currentOrderLoading = true
        } else if (type === 'confirmed') {
          this.confirmedOrderLoading = true
        }
        const params = { page: page, page_size: pageSize }

        const response = await api.get(`orders/${id}/lines/`, { params: params })
        if (type === 'current') {
          this.currentOrderLines = response.data.results
          this.countCurrentLines = response.data.count
          return this.currentOrderLines
        } else if (type === 'confirmed') {
          this.confirmedOrderLines = response.data.results as OrderLine[]
          this.countConfirmedLines = response.data.count
          return this.confirmedOrderLines
        }
        return [] as OrderLine[]
      } catch (error) {
        this.hasError = true
        this.confirmedOrderLines = [] as OrderLine[]
        return this.confirmedOrderLines
      } finally {
        if (type === 'current') {
          this.currentOrderLoading = false
        } else if (type === 'confirmed') {
          this.confirmedOrderLoading = false
        }
      }
    }
  }
})
